export default {
  data () {
    return {
      formErrors: {},
      formStatus: 0,
      formIsLoading: false
    }
  },
  methods: {
    getFormError: function (field) {
      field = 'data.' + field

      if (typeof this.formErrors === 'undefined') {
        return []
      }

      if (typeof this.formErrors[field] === 'undefined') {
        return []
      }

      if (typeof this.formErrors[field] !== 'object') {
        return [this.$i18n.t(this.formErrors[field])]
      }

      if (this.formErrors[field] === null) {
        return []
      }

      return this.$i18n.t(this.formErrors[field])
    },
    hasFormError: function (field) {
      if (typeof field === 'object') {
        let error = 0
        field.forEach(x => {
          error += this.getFormError(x).length > 0
        })

        return error > 0
      }

      return this.getFormError(field).length > 0
    },
    scrollToError: function () {
      this.$scrollTo('#form-errors')
    },
    resetFormErrors: function () {
      this.formStatus = 0
      this.formErrors = {}
      this.formIsLoading = false
      this.$forceUpdate()
    },
    submitForm: function (afterPromiseCallback) {
      // reset first before doing anything
      this.resetFormErrors()

      this.$v.$touch()
      if (this.$v.$error) {
        return Promise.reject(false)
      }

      this.formIsLoading = true
      return Promise.resolve(afterPromiseCallback())
    },
    finishFormPositive: function () {
      this.formStatus = true
      this.formIsLoading = false
    },
    finishFormNegative: function (formErrors) {
      this.formErrors = formErrors
      this.formStatus = false
      this.formIsLoading = false
    }
  }
}

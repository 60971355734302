<template>
  <form>
    <div v-if="additionNeeded">
      <div class="col-12">
        <div class="alert alert-info">
          Seit dem 1. Januar 2021 muss jede Fahrschülerin und jeder Fahrschüler alle 3 Motorradkurse (Kurs 1, Kurs 2 und
          Kurs 3) besuchen. Dies ist unabhängig von der Kategorie und des Motorrads, welches die Fahrschülerin oder der
          Fahrschüler fährt.
        </div>
      </div>

      <div v-if="parentCourses" class="course-list">
        <h3 class="typed">Vorangehende Kursteile</h3>
        <!-- parentCourses are courses than must be visited EARLIER than the current course -->
        <div v-if="parentCourses" class="row">
          <div v-for="(course, index) in parentCourses.filter(x => !x.full)"
               :key="index"
               class="col-12 course-list-item">
            <div class="row">
              <div class="col-1">
                <input type="radio"
                       v-model="additionalCourse[course.type_name]"
                       :name="course.type_name"
                       :value="course.id" />
              </div>
              <div class="col">
                <p class="no padding margin">
                  <b>{{ course.type_name }}</b>
                </p>
                <p class="no padding margin">
                  {{ course.name }}
                </p>
              </div>
              <div class="col">
                <p v-if="course.lections && course.lections.length > 0" class="no padding margin">
                  <b>{{ course.lections[0].location_name }}</b><br />
                </p>
                <p class="no padding margin">
                  <i class="fa fa-usd"></i>CHF {{ course.price }} pro Kursteil
                </p>
              </div>
            </div>
          </div>
          <div v-for="(item, index) in parentCourses.map(x => x.type_name).filter((v, i, s) => s.indexOf(v) === i)"
               :key="index"
               class="col-12 course-list-item">
            <div class="row">
              <div class="col-1">
                <input type="radio" v-model="additionalCourse[item]" :name="item" :value="null" />
              </div>
              <div class="col">
                {{ item }} nicht anmelden oder bereits besucht (Nachweis erforderlich)
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="childCourses" class="course-list">
        <h3 class="typed">Nachfolgende Kursteile</h3>
        <!-- childCourses are courses than can be visited LATER than the current course -->
        <div class="row">
          <div v-for="(course, index) in childCourses.filter(x => !x.full)"
               :key="index"
               class="col-12 course-list-item">
            <div class="row">
              <div class="col-1">
                <input type="radio"
                       v-model="additionalCourse[course.type_name]"
                       :name="course.type_name"
                       :value="course.id" />
              </div>
              <div class="col">
                <p class="no padding margin">
                  <b>{{ course.type_name }}</b>
                </p>
                <p class="no padding margin">
                  {{ course.name }}
                </p>
              </div>
              <div class="col">
                <p v-if="course.lections && course.lections.length > 0" class="no padding margin">
                  <b>{{ course.lections[0].location_name }}</b><br />
                </p>
                <p class="no padding margin">
                  <i class="fa fa-usd"></i>CHF {{ course.price }} pro Kursteil
                </p>
              </div>
            </div>
          </div>
          <div v-for="(item, index) in childCourses.map(x => x.type_name).filter((v, i, s) => s.indexOf(v) === i)"
               :key="index"
               class="col-12 course-list-item">
            <div class="row">
              <div class="col-1">
                <input type="radio" v-model="additionalCourse[item]" :name="item" :value="null" />
              </div>
              <div class="col">
                {{ item }} nicht anmelden oder bereits besucht (Nachweis erforderlich)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3>Deine Daten</h3>
    <div class="row">
      <form-group type="text"
                  class="col-12 col-md-4"
                  label="Vorname"
                  invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                  placeholder=""
                  field-name="firstName"
                  :required="true"
                  :value.sync="form.firstName"></form-group>
      <form-group type="text"
                  class="col-12 col-md-4"
                  label="Nachname"
                  invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                  placeholder=""
                  field-name="lastName"
                  :required="true"
                  :value.sync="form.lastName"></form-group>
      <form-group type="date"
                  class="col-12 col-md-4"
                  label="Geburtsdatum"
                  invalidMessage="Bitte wähle ein Datum."
                  placeholder=""
                  field-name="birthdate"
                  :required="true"
                  :value.sync="form.birthdate"></form-group>
    </div>
    <div class="row">
      <form-group type="text"
                  class="col-12 col-md-6"
                  label="Strasse"
                  invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                  placeholder=""
                  field-name="street"
                  :required="true"
                  :value.sync="form.street"></form-group>
      <form-group type="text"
                  class="col-12 col-md-6"
                  label="PLZ und Ort"
                  invalidMessage="Bitte gib mehr als 6 Zeichen ein."
                  placeholder=""
                  field-name="city"
                  :required="true"
                  :value.sync="form.city"></form-group>
    </div>

    <h3>Deine Kontaktdaten</h3>
    <div class="row">
      <form-group type="text"
                  class="col-12 col-md-6"
                  label="E-Mail Addresse"
                  invalidMessage="Bitte gib eine gültige E-Mail-Addresse ein."
                  placeholder=""
                  field-name="mail"
                  :required="true"
                  :value.sync="form.mail"></form-group>

      <form-group type="text"
                  class="col-12 col-md-6"
                  label="Telefon"
                  invalidMessage="Bitte gib eine gültige Telefonnummer ein."
                  placeholder=""
                  field-name="phone"
                  :required="true"
                  :value.sync="form.phone"></form-group>
    </div>

    <h3>Deine Ausweisdaten</h3>
    <p class="alert alert-info">
      Um am Kurs teilnehmen zu können, musst Du im Besitz eines gültigen Lernfahrausweieses sein.
      <router-link :to="{name: 'license-info'}" target="_blank">Wo finde ich meine Ausweisnummer?</router-link>
    </p>
    <div class="row">
      <form-group type="text"
                  class="col-12 col-md-6"
                  label="Lernfahrausweis-Nummer"
                  invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                  placeholder=""
                  field-name="regNumber"
                  :required="true"
                  :value.sync="form.regNumber"></form-group>

      <form-group type="date"
                  class="col-12 col-md-6"
                  label="Lernfahrausweis gültig bis"
                  invalidMessage="Bitte wähle ein Datum."
                  placeholder=""
                  field-name="regValidUntil"
                  :required="true"
                  :value.sync="form.regValidUntil"></form-group>
    </div>

    <h3>Dein Motorrad</h3>
    <p class="alert alert-info">
      Wir benötigen die folgenden Informationen um sicherzustellen, dass dein Motorrad für den Kurs bzw. eine
      praktische Prüfung zugelassen ist. Du findest alle diese Informationen im Fahrzeugausweis.
    </p>
    <div class="row">
      <form-group type="text"
                  class="col-12 col-md-6"
                  label="Marke des Motorrads"
                  invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                  placeholder="z.B. Honda"
                  field-name="moto_manufacturer"
                  :required="false"
                  :value.sync="form.moto_manufacturer"></form-group>
      <form-group type="text"
                  class="col-12 col-md-6"
                  label="Modell des Motorrads"
                  invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                  placeholder="z.B. CBJ 500"
                  field-name="moto_model"
                  :required="false"
                  :value.sync="form.moto_model"></form-group>
    </div>
    <div class="row">
      <form-group type="text"
                  class="col-12 col-md-6"
                  label="Leistung des Motorrads in kW"
                  invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                  placeholder="z.B. 35 kW"
                  field-name="moto_kw"
                  :required="false"
                  :value.sync="form.moto_kw"></form-group>
      <form-group type="text"
                  class="col-12 col-md-6"
                  label="Hubraum des Motorrads in ccm"
                  invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                  placeholder="z.B. 750ccm"
                  field-name="moto_ccm"
                  :required="false"
                  :value.sync="form.moto_ccm"></form-group>
    </div>

    <p v-show="hasAnyError">
      <CIcon name="cil-warning" class="red" />
      Bitte überprüfe das Formular. Einige Felder sind nicht korrekt ausgefüllt.
    </p>

    <p>
      <button class="btn btn-primary" @click="emitSubmit" :disabled="hasAnyError">
        Kostenpflichtig anmelden
      </button>
    </p>
  </form>
</template>

<script>
import CourseService from '@/services/CourseService';
import FormGroup from '@/common/Form/FormGroup';
import FormHandler from '@/common/Form/FormHandler';
import {required, minLength, email, maxLength} from 'vuelidate/lib/validators';
import {validationMixin} from 'vuelidate';

export default {
  name: 'MotoSignupForm',
  components: {FormGroup},
  mixins: [FormHandler, validationMixin],
  props: {
    course: {
      type: [String, Array],
      required: true,
      default: null,
    }
  },
  data() {
    return {
      critical_course: '',
      additionNeeded: false,
      parentCourses: null,
      childCourses: null,
      additionalCourse: {},
      form: {
        firstName: null,
        lastName: null,
        street: null,
        city: null,
        birthday: null,
        regNumber: null,
        regValidUntil: null,
        mail: null,
        phone: null,
        moto_kw: null,
        moto_ccm: null,
        moto_manufacturer: null,
        moto_model: null
      }
    };
  },
  validations() {
    return {
      form: {
        firstName: {
          required,
          minLength: minLength(2)
        },
        lastName: {
          required,
          minLength: minLength(2)
        },
        street: {
          required,
          minLength: minLength(2)
        },
        city: {
          required,
          minLength: minLength(6)
        },
        birthdate: {
          required
        },
        mail: {
          email,
          required
        },
        phone: {
          minLength: minLength(10),
          required
        },
        regValidUntil: {
          required
        },
        regNumber: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(10)
        }
      }
    };
  },
  computed: {
    hasAnyError: function () {
      return this.$v.$anyError;
    }
  },
  mounted() {
    this.loadParentCourse();
    this.loadChildrenCourse();
  },
  methods: {
    loadParentCourse() {
      let self = this;
      let datearray = self.course[0].lections[0].begin.split('T');
      let datestring = datearray[0];

      CourseService
          .getParentCourses(self.course[0].parentlookups, datestring)
          .then(response => {
            self.additionNeeded = (response.data[0] !== null);
            this.parentCourses = response.data;
          });
    },
    loadChildrenCourse() {
      let self = this;
      let datearray = self.course[0].lections[0].begin.split('T');
      let datestring = datearray[0];

      CourseService
          .getChildCourses(self.course[0].typeId, datestring)
          .then(response => {
            self.additionNeeded = (response.data[0] !== null);
            this.childCourses = response.data;
          });
    },
    emitSubmit: function () {
      this.submitForm(() => null)
          .then(() => {
            let formData = {
              // the additional courses are saved as "typeName => courseId", so select non-null values
              additionalcourses: Object.values(this.additionalCourse).filter(x => x !== null),
              name: this.form.firstName + ' ' + this.form.lastName,
              street: this.form.street,
              phone: this.form.phone,
              city: this.form.city.split(' ')[0],
              zip: this.form.city.split(' ')[1],
              mail: this.form.mail,
              metadata: {
                birthday: this.form.birthdate,
                lf_reg: this.form.regNumber,
                lf_date: this.form.regValidUntil,
                moto_man: this.form.moto_manufacturer,
                moto_mod: this.form.moto_model,
                moto_ccm: this.form.moto_ccm,
                moto_power: this.form.moto_kw
              }
            };

            this.$emit('submit', formData);
          });
    }
  }
};
</script>

<style lang="scss" scoped>
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.3;
}

input[type="checkbox"] + label:before {
  width: 40px;
  height: 20px;
  border-radius: 30px;
  border: 2px solid #ddd;
  background-color: #EEE;
  content: "";
  margin-right: 15px;
  transition: background-color 0.5s linear;
}

input[type="checkbox"] + label:after {
  width: 20px;
  height: 20px;
  border-radius: 30px;
  background-color: #fff;
  content: "";
  transition: margin 0.1s linear;
  box-shadow: 0 0 5px #aaa;
  position: absolute;
  left: 2px;
  top: 2px;
}

input[type="checkbox"]:checked + label:before {
  background-color: #2b8718;
}

input[type="checkbox"]:checked + label:after {
  margin: 0 0 0 20px;
}

div.form {
  padding: 0.5rem;

  div.row div {
    padding: 0.3em 0.3em 0.1em;
  }

  div {
    margin-bottom: 0.3rem;
  }

  div:first-of-type {
    margin-top: 0.3rem;
  }

  div:last-of-type {
    margin-bottom: 0.3rem;
  }

  @media (max-device-width: 481px) {
    div:first-of-type {
      margin-top: 0.5rem;
    }

    div:last-of-type {
      margin-bottom: 0.5rem;
    }

    div.col-center {
      text-align: center;

      p {
        text-align: center;
      }
    }
  }
}

div.course-list-item {
  margin-bottom: 0.8rem;
}
</style>

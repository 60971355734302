<template>
  <form>
    <h3>Deine Daten</h3>
    <div class="row">
      <form-group type="text"
                  class="col-12 col-md-4"
                  label="Vorname"
                  invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                  placeholder=""
                  field-name="firstName"
                  :required="true"
                  :value.sync="form.firstName"></form-group>
      <form-group type="text"
                  class="col-12 col-md-4"
                  label="Nachname"
                  invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                  placeholder=""
                  field-name="lastName"
                  :required="true"
                  :value.sync="form.lastName"></form-group>
      <form-group type="date"
                  class="col-12 col-md-4"
                  label="Geburtsdatum"
                  invalidMessage="Bitte wähle ein Datum."
                  placeholder=""
                  field-name="birthdate"
                  :required="true"
                  :value.sync="form.birthdate"></form-group>
    </div>
    <div class="row">
      <form-group type="text"
                  class="col-12 col-md-6"
                  label="Strasse"
                  invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                  placeholder=""
                  field-name="street"
                  :required="true"
                  :value.sync="form.street"></form-group>
      <form-group type="text"
                  class="col-12 col-md-6"
                  label="PLZ und Ort"
                  invalidMessage="Bitte gib mehr als 6 Zeichen ein."
                  placeholder=""
                  field-name="city"
                  :required="true"
                  :value.sync="form.city"></form-group>
    </div>

    <h3>Deine Kontaktdaten</h3>
    <div class="row">
      <form-group type="text"
                  class="col-12 col-md-6"
                  label="E-Mail Addresse"
                  invalidMessage="Bitte gib eine gültige E-Mail-Addresse ein."
                  placeholder=""
                  field-name="mail"
                  :required="true"
                  :value.sync="form.mail"></form-group>

      <form-group type="text"
                  class="col-12 col-md-6"
                  label="Telefon"
                  invalidMessage="Bitte gib eine gültige Telefonnummer ein."
                  placeholder=""
                  field-name="phone"
                  :required="true"
                  :value.sync="form.phone"></form-group>
    </div>

    <h3>Deine Ausweisdaten</h3>
    <p class="alert alert-info">
      Um am Kurs teilnehmen zu können, musst Du im Besitz eines gültigen Lernfahrausweieses sein.
      <router-link :to="{name: 'license-info'}" target="_blank">Wo finde ich meine Ausweisnummer?</router-link>
    </p>
    <div class="row">
      <form-group type="text"
                  class="col"
                  label="Lernfahrausweis-Nummer"
                  invalidMessage="Die Ausweis-Nummer ist zwischen 5 und 10 Zeichen lang."
                  placeholder=""
                  field-name="regNumber"
                  :required="true"
                  :value.sync="form.regNumber"></form-group>

      <form-group type="date"
                  class="col"
                  label="Lernfahrausweis gültig bis"
                  invalidMessage="Bitte wähle ein Datum."
                  placeholder=""
                  field-name="regValidUntil"
                  :required="true"
                  :value.sync="form.regValidUntil"></form-group>
    </div>

    <p v-show="hasAnyError">
      <CIcon name="cil-warning" class="red" />
      Bitte überprüfe das Formular. Einige Felder sind nicht korrekt ausgefüllt.
    </p>

    <p>
      <button class="btn btn-primary" @click="emitSubmit" :disabled="hasAnyError">
        Kostenpflichtig anmelden
      </button>
    </p>

  </form>
</template>

<script>
import CourseService from '@/services/CourseService';
import FormGroup from '@/common/Form/FormGroup';
import FormHandler from '@/common/Form/FormHandler';
import {required, minLength, email, maxLength} from 'vuelidate/lib/validators';
import {validationMixin} from 'vuelidate';

export default {
  name: 'VkuSignupForm',
  components: {FormGroup},
  mixins: [FormHandler, validationMixin],
  data() {
    return {
      course: null,
      form: {
        firstName: null,
        lastName: null,
        street: null,
        city: null,
        birthdate: null,
        regNumber: null,
        regValidUntil: null,
        mail: null,
        phone: null
      }
    };
  },
  validations() {
    return {
      form: {
        firstName: {
          required,
          minLength: minLength(2)
        },
        lastName: {
          required,
          minLength: minLength(2)
        },
        street: {
          required,
          minLength: minLength(2)
        },
        city: {
          required,
          minLength: minLength(6)
        },
        birthdate: {
          required
        },
        mail: {
          email,
          required
        },
        phone: {
          minLength: minLength(10),
          required
        },
        regValidUntil: {
          required
        },
        regNumber: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(10)
        }
      }
    };
  },
  computed: {
    hasAnyError() {
      return this.$v.$anyError;
    }
  },
  methods: {
    loadCourse() {
      CourseService
          .getCourse(this.courseId)
          .then(response => this.course = response.data);
    },
    emitSubmit: function () {
      this.submitForm(() => null)
          .then(() => {
            let formData = {
              name: this.form.firstName + ' ' + this.form.lastName,
              street: this.form.street,
              phone: this.form.phone,
              city: this.form.city.split(' ')[0],
              zip: this.form.city.split(' ')[1],
              mail: this.form.mail,
              metadata: {
                birthday: this.form.birthdate,
                lf_reg: this.form.regNumber,
                lf_date: this.form.regValidUntil
              }
            };

            this.$emit('submit', formData);
          })
          .catch(() => {
            console.log('ERROR!');
          });
    }
  }
};
</script>

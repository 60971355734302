<template>
  <div>
    <course-list-component
        :courses="course"
        :show-signup="false"></course-list-component>

    <div class="card">
      <div class="card-body">
        <div v-if="isSuccessful === true">
          <div class="alert alert-success">
            Deine Anmeldung wurde übermittelt. Du bekommst in jedem Fall eine Bestätigung per E-Mail (üblicherweise
            innerhalb eines Werktages).
          </div>
          <p>
            Solltest du <b>keine</b> Bestätigung per E-Mail erhalten habe, nimm bitte mit uns Kontakt auf. In diesem
            Falle ist der Kurs oder die Kursteilnahme nicht garantiert.
          </p>
        </div>
        <div v-else-if="isSuccessful === false">
          <div class="alert alert-danger">
            Wir konnten deine Anmeldung leider nicht verarbeiten.
            <span v-if="errorMessage"><b>{{ errorMessage }}</b></span>
            Bitte nimm telefonisch oder per E-Mail mit uns Kontakt auf. Wir entschuldigen uns für diesen
            Unannehmlichkeit.
          </div>
        </div>
        <div v-else-if="isLoading">
          <p class="text-center">
            <CSpinner component="span" size="sm" aria-hidden="true"/>
          </p>
          <p class="text-center">
            Deine Anmeldung wird verarbeitet.
          </p>
        </div>
        <div v-else-if="course && !isLoading">
          <vku-signup-form v-if="course[0].type_name.toLowerCase().startsWith('vku')"
                           v-on:submit="onSubmit"></vku-signup-form>

          <moto-signup-form v-if="course[0].type_name.toLowerCase().startsWith('moto')" :course="course"
                            v-on:submit="onSubmit"></moto-signup-form>

          <generic-signup-form
              v-if="!course[0].type_name.toLowerCase().startsWith('moto') && !course[0].type_name.toLowerCase().startsWith('vku')"
              v-on:submit="onSubmit"></generic-signup-form>
        </div>
        <div v-else class="text-center">
          <CSpinner component="span" size="sm" aria-hidden="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CourseService from '@/services/CourseService';
import CourseListComponent from '@/views/Components/CourseListComponent';
import VkuSignupForm from '@/views/SignupForms/VkuSignupForm';
import MotoSignupForm from '@/views/SignupForms/MotoSignupForm';
import GenericSignupForm from '@/views/SignupForms/GenericSignupForm';

export default {
  name: 'Signup',
  components: { GenericSignupForm, MotoSignupForm, VkuSignupForm, CourseListComponent },
  props: {
    courseId: {
      type: [Number, Array],
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      isSuccessful: null,
      course: null,
      errorMessage: null,
    };
  },
  computed: {
    title: (_this) => {
      if (_this.course) {
        return _this.course[0].type_name;
      }
    }
  },
  mounted() {
    this.loadCourse();
  },
  methods: {
    loadCourse() {
      CourseService
          .getCourse(this.courseId)
          .then(response => this.course = [response.data]);
    },
    onSubmit(formData) {
      this.isLoading = true;
      this.isSuccessful = null;

      CourseService
          .addParticipant(this.courseId, formData)
          .then(() => {
            this.isLoading = false;
            this.isSuccessful = true;
          })
          .catch(err => {
            console.log(err)
            if (err.response && err.response.data && err.response.data.error) {
              this.errorMessage = err.response.data.error
            }

            this.isLoading = false;
            this.isSuccessful = false;
          });
    }
  }
};
</script>

<style lang="scss" scoped>
.green {
  color: green;
}
</style>

<template>
  <div class="form-group"
       v-bind:class="{ 'has-error': _hasFormError() || _hasValidationError() || forceError, 'checkbox': type === 'checkbox'}">
    <label class="control-label"
           :for="fieldName"
           v-bind:class="{required: required}"
           v-if="label && type !== 'checkbox'">{{ label }} </label>
    <slot name="input-label"></slot>
    <slot>
      <!-- TEXT TYPE -->
      <input
              class="form-control"
              v-if="type === 'text'" type="text"
              v-bind:title="label"
              :value="value"
              :placeholder="placeholder"
              v-on:input="$emit('update:value', $event.target.value) && _touch()"
              :name="fieldName"
              v-bind:required="required"/>
      <!-- CHECKBOX TYPE -->
      <input :id="fieldName"
             class="form-control"
             v-model="model"
             v-if="type === 'checkbox'" type="checkbox"
             v-bind:title="label"
             v-on:input="$emit('update:value', $event.target.value) && _touch()"
             v-on:change="_emit"
             :name="fieldName"
             v-bind:required="required"/>
      <!-- PASSWORD TYPE -->
      <input
              class="form-control"
              v-if="type === 'password'" type="password"
              v-bind:title="label"
              :value="value"
              :placeholder="placeholder"
              v-on:input="$emit('update:value', $event.target.value) && _touch()"
              :name="fieldName"
              v-bind:required="required"/>
      <!-- MAIL TYPE -->
      <input
              class="form-control"
              v-if="type === 'email'" type="email"
              v-bind:title="label"
              :value="value"
              :placeholder="placeholder"
              v-on:input="$emit('update:value', $event.target.value) && _touch()"
              :name="fieldName"
              v-bind:required="required"/>
      <!-- NUMBER TYPE -->
      <input
              class="form-control"
              v-if="type === 'number'" type="number"
              v-bind:title="label"
              :value="value"
              :placeholder="placeholder"
              v-on:input="$emit('update:value', $event.target.value) && _touch()"
              :name="fieldName"
              v-bind:required="required"/>
      <!-- URL TYPE -->
      <input
              class="form-control"
              v-if="type === 'url'" type="url"
              v-bind:title="label"
              :value="value"
              :placeholder="placeholder"
              v-on:input="$emit('update:value', $event.target.value) && _touch()"
              :name="fieldName"
              v-bind:required="required"/>
      <!-- HOUR TYPE -->
      <input
              class="form-control"
              v-if="type === 'time'" type="time"
              v-bind:title="label"
              :value="value"
              :placeholder="placeholder"
              v-on:input="$emit('update:value', $event.target.value) && _touch()"
              :name="fieldName"
              v-bind:required="required"/>
      <!-- DATE TYPE -->
      <input
              class="form-control"
              v-if="type === 'date'" type="date"
              v-bind:title="label"
              :value="value"
              :placeholder="placeholder"
              v-on:input="$emit('update:value', $event.target.value) && _touch()"
              :name="fieldName"
              v-bind:required="required"/>
      <!-- SELECT TYPE -->
      <select
              class="form-control"
              v-if="type === 'select'"
              v-model="model"
              v-on:change="_emit"
              v-on:select="$emit('update:value', $event.target.value) && _touch()"
              :name="fieldName"
              v-bind:required="required">
        <option v-for="(label, value) in choices" :key="label" :value="value">{{ label }}</option>
      </select>
    </slot>
    <label class="control-label"
           :for="fieldName"
           v-bind:class="{required: required}"
           v-if="label && type === 'checkbox'">{{ label }} </label>
    <!-- HELP BLOCKS -->
    <span class="help-block"
          v-show="_hasValidationError() || forceError">{{ invalidMessage }}</span>
    <span class="help-block" v-for="error in _getFormError()"> {{ error }}</span>
  </div>
</template>

<script>
  export default {
    name: 'FormGroup',
    props: {
      // input field type (e.g. text)
      type: {
        type: String,
        required: true
      },
      // the label to present, or null if none
      label: {
        type: String,
        required: false
      },
      // the field name
      fieldName: {
        type: String,
        required: true
      },
      // the name of the form group (nested validation)
      groupName: {
        type: String,
        required: false,
        default: null
      },
      // field is required (or not)
      required: {
        type: Boolean,
        required: false,
        default: false
      },
      // the message to present when the input is invalid
      invalidMessage: {
        type: String,
        required: false,
        default: 'This value is not valid.'
      },
      // the placeholder for the input
      placeholder: {
        type: String,
        required: false
      },
      // if true, force error on field
      forceError: {
        default: false
      },
      // if this is an select, these are the options
      choices: {
        type: Object | Array,
        required: false
      },
      // the value (use :value.sync
      value: {}
    },
    data () {
      return {
        model: null
      }
    },
    mounted () {
      console.log('mount with ' + this.value)
      this.model = this.value
    },
    watch: {
      value: {
        handler: function (newVal) {
          // TODO: verify whether this can be extend to checkboxes
          // as of now, we did not extend it because we didn't wanted to test it
          if (this.type === 'select') {
            this.model = newVal
          }
        }
      }
    },
    methods: {
      __v: function (action) {
        let parent = this
        while (parent.$parent) {
          parent = parent.$parent
          if (typeof parent.$v !== 'undefined' && typeof parent.$v.form !== 'undefined') {
            let targetField = parent.$v.form

            if (this.groupName) {
              targetField = targetField[this.groupName]
            }
            if (typeof targetField === 'undefined' || typeof targetField[this.fieldName] === 'undefined') {
              // warn about undefined fields, but act like no error was found
              if (this.fieldName) {
                console.warn('[form] Undefined field name in form: <' + this.fieldName + '> in <' + this.groupName + '>')
              } else {
                console.warn('[form] No field name for field with label <' + this.label + '> given')
              }

              return action({$error: false})
            }

            return action(targetField[this.fieldName])
          }
        }
      },
      _touch: function () {
        this.__v(target => {
          if (typeof target.$touch === 'undefined') {
            console.warn('[form] Unable to touch target for <' + this.field + '>')
            return
          }
          target.$touch()
        })
      },
      _emit () {
        if (this.type !== 'checkbox' && this.type !== 'select') {
          this.model = this.value
        }
        this.$emit('value-change', this.model)
      },
      _hasValidationError: function () {
        return this.__v(target => {
          // because we $touch the element on input, we can rely on $error
          // $dirty is not reliable by itself because of component
          return target.$error
        })
      },
      _hasFormError: function () {
        let parent = this
        while (parent.$parent) {
          parent = parent.$parent
          if (typeof parent.hasFormError !== 'undefined') {
            return parent.hasFormError(this.fieldName)
          }
        }

        console.log('[form] No parent found to validate form errors, mixin missing?')
      },
      _getFormError: function () {
        let parent = this
        while (parent.$parent) {
          parent = parent.$parent
          if (typeof parent.getFormError !== 'undefined') {
            return parent.getFormError(this.fieldName)
          }
        }

        console.log('[form] No parent found to validate form errors, mixin missing?')
      }
    }
  }
</script>

<style scoped>
  label.required::after {
    content: "*";
  }
</style>

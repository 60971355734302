<template>
  <div>
    <div class="form">
      <h3>Deine Daten</h3>
      <form>
        <form-group type="text"
                    label="Vorname"
                    invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                    placeholder=""
                    field-name="firstName"
                    :required="true"
                    :value.sync="form.firstName"></form-group>

        <form-group type="text"
                    label="Nachname"
                    invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                    placeholder=""
                    field-name="lastName"
                    :required="true"
                    :value.sync="form.lastName"></form-group>

        <form-group type="text"
                    label="Strasse"
                    invalidMessage="Bitte gib mehr als 2 Zeichen ein."
                    placeholder=""
                    field-name="street"
                    :required="true"
                    :value.sync="form.street"></form-group>

        <form-group type="text"
                    label="PLZ und Ort"
                    invalidMessage="Bitte gib mehr als 6 Zeichen ein."
                    placeholder=""
                    field-name="city"
                    :required="true"
                    :value.sync="form.city"></form-group>

        <form-group type="date"
                    label="Geburtsdatum"
                    invalidMessage="Bitte wähle ein Datum."
                    placeholder=""
                    field-name="birthdate"
                    :required="true"
                    :value.sync="form.birthdate"></form-group>
      </form>
    </div>

    <div class="form">
      <h3>Deine Kontaktdaten</h3>
      <form>
        <form-group type="text"
                    label="E-Mail Addresse"
                    invalidMessage="Bitte gib eine gültige E-Mail-Addresse ein."
                    placeholder=""
                    field-name="mail"
                    :required="true"
                    :value.sync="form.mail"></form-group>

        <form-group type="text"
                    label="Telefon"
                    invalidMessage="Bitte gib eine gültige Telefonnummer ein."
                    placeholder=""
                    field-name="phone"
                    :required="true"
                    :value.sync="form.phone"></form-group>
      </form>
    </div>

    <p class="center" v-show="hasAnyError">
      <i class="fa fa-info-circle red"></i><br />
      Bitte überprüfe das Formular. Einige Felder sind nicht korrekt ausgefüllt.
    </p>

    <div class="col-md-12 center">
      <button class="btn btn-primary" @click="emitSubmit"
              :disabled="hasAnyError">Anmelden
      </button>
    </div>
  </div>
</template>

<script>
import CourseService from '@/services/CourseService';
import FormGroup from '@/common/Form/FormGroup';
import FormHandler from '@/common/Form/FormHandler';
import {required, minLength, email} from 'vuelidate/lib/validators';
import {validationMixin} from 'vuelidate';

export default {
  name: 'GenericSignupForm',
  mixins: [FormHandler, validationMixin],
  components: {FormGroup},
  data() {
    return {
      course: null,
      form: {
        firstName: null,
        lastName: null,
        street: null,
        city: null,
        birthdate: null,
        mail: null,
        phone: null
      }
    };
  },
  validations() {
    return {
      form: {
        firstName: {
          required,
          minLength: minLength(2)
        },
        lastName: {
          required,
          minLength: minLength(2)
        },
        street: {
          required,
          minLength: minLength(2)
        },
        city: {
          required,
          minLength: minLength(6)
        },
        birthdate: {
          required
        },
        mail: {
          email,
          required
        },
        phone: {
          minLength: minLength(10),
          required
        }
      }
    };
  },
  computed: {
    hasAnyError: function () {
      return this.$v.$anyError;
    }
  },
  mounted() {
    this.loadCourse();
  },
  methods: {
    loadCourse() {
      return CourseService
          .getCourse(this.courseId)
          .then(response => this.course = [response.data]);
    },
    emitSubmit: function () {
      this.submitForm(() => {
      })
          .then(() => {
            let formData = {
              name: this.form.firstName + ' ' + this.form.lastName,
              street: this.form.street,
              phone: this.form.phone,
              city: this.form.city.split(' ')[1],
              zip: this.form.city.split(' ')[0],
              mail: this.form.mail,
              metadata: {
                birthday: this.form.birthdate
              }
            };

            this.$emit('submit', formData);
          });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
